<template>
  <div class="member_user">
    <UserInfo>
      <div class="top_main_info member_bg">
        <div class="avatar avatar_cinema" @click="show=true"></div>
        <div class="vip_user" @click="show=true">
          <div class="vip_name">{{userName}}</div>
          <div class="vip_flag">
            <div class="vip_flag_v1"></div>
            <div class="vip_flag_v2"><img src="../assets/VIP.png" ></div>
          </div>
        </div>
      </div>
    </UserInfo>
    <HomeNav></HomeNav>
    <div class="mb" @click="show=false" v-if="show">
      <div class="pannel">
        <div class="avatar"></div>
        <div class="vip_name">{{userName}}</div>
        <div class="vip_flag">
          <div class="vip_flag_v1"></div>
          <div class="vip_flag_v2"><img src="../assets/VIP.png" ></div>
        </div>
        <div class="btn" @click="exit">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Image as VanImage, Toast } from 'vant';
import UserInfo from "../components/UserInfo";
import HomeNav from "../components/HomeNav";
export default {
  name: "MemberUser",
  beforeRouteEnter(to,from,next){
    //判断用户身份是否正确 会员 为 1
    if(localStorage.getItem("identity")==1){
      next(true)
    }else{
      localStorage.removeItem("identity");
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      next("/cinemaLogin");
    }
  },
  created() {
    if(localStorage.getItem("userName")&&localStorage.getItem("token")){
      this.userName=localStorage.getItem("userName");
    }else{
      //  未在缓存里找到用户信息，提示用户需要重新登录
      Toast({message:"请重新登录",icon:"cross"})
      this.$router.push("/cinemaLogin")
    }
  },
  data () {
    return {
      userName:'',
      show:false
    }
  },
  components:{
    [VanImage.name]:VanImage,
    UserInfo,HomeNav
  },
  methods:{
    exit(){
      Toast.success({message:"退出登录"})
      localStorage.removeItem("identity");
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      this.$router.push("/cinemaLogin");
    }
  }
}
</script>

<style scoped>
.mb {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.36);
  box-shadow: 0px 2px 7px 3px rgba(112, 112, 112, 0.06);
}
.mb .pannel {
  width: 614px;
  height: 546px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px 6px rgba(112, 112, 112, 0.06);
  border-radius: 54px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 336px;
  left: 68px;
  background: url("../assets/Group 359.png") center center / 100% 100% no-repeat content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mb .pannel .avatar {
  margin-top: 93px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-image: url("../assets/avatar_cinema.png");
  background-size: 120px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.mb .pannel .vip_name {
  margin: 10px 0;
  height: 46px;
  font-size: 32px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #007FFF;
  line-height: 46px;
}
.mb .pannel .vip_flag .vip_flag_v1 {
  float: left;
  width: 28px;
  height: 28px;
  background-image: url("../assets/Group 207.png");
  background-size: 28px;
}
.mb .pannel .vip_flag .vip_flag_v2 {
  float: left;
  width: 94px;
  height: 28px;
  margin-left: 16px;
  background: linear-gradient(92deg, #00C2FF 0%, #0080FF 100%);
  border-radius: 31px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mb .pannel .vip_flag .vip_flag_v2 img {
  width: 52px;
  height: 18px;
}
.mb .pannel .btn {
  margin-top: 85px;
  width: 375px;
  height: 81px;
  background: #5A77FD;
  border-radius: 41px;
  opacity: 0.87;
  font-size: 32px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 81px;
  text-align: center;
}
</style>
