<template>
  <div class="nav">
    <div class="nav_l" @click="toMovieInfoAdd"></div>
    <div class="nav_r">
      <div class="top" @click="toCinemaAdd"></div>
      <div class="bottom" @click="toFileDownload"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home_nav",
  methods:{
    toMovieInfoAdd(){
      this.$router.push("/movieInfoAdd")
    },
    toCinemaAdd(){
      this.$router.push("/cinemaAdd")
    },
    toFileDownload(){
      this.$router.push("/fileDownload")
    }
  }
}
</script>

<style scoped>
.nav {
  width: calc(640px);
  height: calc(307px);
  background: #FFFFFF;
  box-shadow: 0px 4px 13px 5px rgba(112, 112, 112, 0.06);
  border-radius: 21px;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0.03);
  margin: 84px auto 0;
  padding: 35px 25px;
  display: flex;
}
.nav .nav_l {
  width: 310px;
  height: 307px;
  background-image: url("../assets/Group 213.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.nav .nav_r {
  margin-left: 25px;
  width: 310px;
  height: 307px;
}
.nav .nav_r .top {
  width: 310px;
  height: 145px;
  background-image: url("../assets/Group 214.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.nav .nav_r .bottom {
  margin-top: 17px;
  width: 310px;
  height: 145px;
  background-image: url("../assets/Group 215.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}

</style>