<template>
  <div class="user_info">
    <div class="top_bg">
      <div class="top_main">
        <div class="top_main_title">电影放映协会</div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfo"
}
</script>

<style scoped>
.user_info{
  width: 750px;
}
.top_bg {
  width: 750px;
  height: 353.5px;
  background-image: url("../assets/Group 189.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
}
.top_bg .top_main {
  width: 690px;
  height: calc(375px);
  margin: 0 auto;
}
.top_bg .top_main .top_main_title {
  width: 100%;
  text-align: center;
  height: 48px;
  font-size: 34px;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 40px;

  padding: 111px 0 42px;
}
.top_bg .top_main .top_main_info {
  width: 750px;
  height: 228px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  position: relative;
  left: -30px;
  display: flex;
  align-items: center;
}
.top_bg .top_main .top_main_info .avatar {
  width: 119px;
  height: 119px;
  border: 5px solid #FFFFFF;
  border-radius: 50%;
  margin: 0 31px 0 50px;
}
.top_bg .top_main .top_main_info .avatar_cinema {
  background-image: url("../assets/avatar_cinema.png");
  background-size: 119px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.top_bg .top_main .top_main_info .avatar_testing {
  background-image: url("../assets/avatar_testing.png");
  background-size: 119px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.top_bg .top_main .top_main_info .un_vip_user .un_vip_name {
  color: #000000;
  width: 500px;
  overflow: hidden;
  height: 45px;
  font-size: 32px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  line-height: 45px;
}
.top_bg .top_main .top_main_info .vip_user {
  height: 88px;
}
.top_bg .top_main .top_main_info .vip_user .vip_name {
  font-size: 32px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  line-height: 45px;
  color: #007FFF;
  width: 502px;
  overflow: hidden;
}
.top_bg .top_main .top_main_info .vip_user .vip_flag {
  height: 28px;
  margin-top: 15px;
}
.top_bg .top_main .top_main_info .vip_user .vip_flag .vip_flag_v1 {
  float: left;
  width: 29px;
  height: 29px;
  background-image: url("../assets/Group 207.png");
  background-size: 29px;
}
.top_bg .top_main .top_main_info .vip_user .vip_flag .vip_flag_v2 {
  float: left;
  width: 94px;
  height: 28px;
  margin-left: 15px;
  background: linear-gradient(92deg, #00C2FF 0%, #0080FF 100%);
  border-radius: 31px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top_bg .top_main .top_main_info .vip_user .vip_flag .vip_flag_v2 img {
  width: 51px;
  height: 17px;
}
.un_member_bg {
  background-image: url("../assets/Rectangle 17.png");
}
.member_bg {
  background-image: url("../assets/Group 231.png");
}
.inspector_bg {
  background-image: url("../assets/Group 227.png");
}

</style>